
'use strict';

/**
 * Authentication lib for making login and register API calls
 * @type {Object}
 */
const Auth = {

    /**
     * Registers an user in the system
     * @param {String} email - The email of the user
     * @param {String} password - The password of the user
     * @param {Function} callback - Called after a user was registered on the remote server
     */
    register(email, password, callback) {
        window.Api.Auth.register(email, password)
            .then((response) => {
                if (response.isOk) {
                    typeof callback === 'function' && callback(false);
                }
                else {
                    typeof callback === 'function' && callback(true, response);
                }
            }, (response) => {
                typeof callback === 'function' && callback(true, response);
            });
    },


    /**
     * Logs a user in
     * @param {String} email - The email address f the user
     * @param {String} password - The password of the user
     * @param {Number} remember - 1 to "remember" the user, 0 for not
     * @param {Function} callback - Called after a user was logged in on the remote server
     */
    login(email, password, remember, callback) {
        const self = this;

        window.Api.Auth.login(email, password)
            .then((response) => {
                if (response.isOk) {
                    self.setAuthState(true);
                    typeof callback === 'function' && callback(false, response);
                }
                else {
                    typeof callback === 'function' && callback(true, response);
                }
            });
    },


    /**
     * Logs the current user out
     * @param {Function} callback - Called after a user was logged out
     */
    logout(callback) {
        const self = this;

        self.removeAuthState();
        window.Api.Auth.logout()
            .then((response) => {
                if (response.isOk) {
                    typeof callback === 'function' && callback(false);
                }
                else {
                    typeof callback === 'function' && callback(true, response);
                }
            }, (response) => {
                typeof callback === 'function' && callback(true, response);
            });
    },


    /**
     * Check if the user is logged in
     * @param {Function} callback - Called after the logged in state is determined
     */
    checkAuthState(callback) {
        const self = this;
        // first check the Session Storage
        if (window.localStorage.isAuthed === 'true') {
            typeof callback === 'function' && callback(true);
        }
        else {
            // then check with the api
            window.Api.Auth.checkLoginStatus()
                .then(({ isAuthed }) => {
                    if (isAuthed) {
                        self.setAuthState('true');
                        typeof callback === 'function' && callback(true);
                    }
                    else {
                        self.setAuthState('false');
                        typeof callback === 'function' && callback(false);
                    }
                });
        }
    },


    /**
     * Set the logged in state in Session Storage
     * @param {Boolean} authState - true when logged in
     */
    setAuthState(authState) {
        window.localStorage.setItem('isAuthed', authState);
    },


    /**
     * Remove the logged in state from Session Storage
     */
    removeAuthState() {
        window.localStorage.removeItem('isAuthed');
    },
};


export default Auth;
