import Auth from 'utils/auth';
import { redirect, getFormattedDate } from 'utils/helpers';
import { codeToRole } from 'config/constants';

'use strict';

const ProfileCard = {

    /**
     * Initialize the ProfileCard features
     */
    init(options) {
        this.options = options || {};

        this.$card = $('.profile-card').removeClass('hidden');
        this.$sprite = this.$card.find('.sprite-container').clone();
        this.$card.find('.sprite-container').remove();

        if (this.options.userMe) {
            this.renderUser();
        }
        this.bindEvents();
    },

    renderUser() {
        this.$card.find('.js-profile-avatar').attr('src', this.options.userMe.avatar);
        this.$card.find('.js-profile-name').text(`${this.options.userMe.first_name}, ${this.options.userMe.last_name}`);
        // this.$card.find('.js-profile-phone').text(this.options.userMe.mobile);
        // this.$card.find('.js-profile-address').text(this.options.userMe.address);
        this.$card.find('.js-profile-join-date').text(getFormattedDate(this.options.userMe.created_at));
        this.$card.find('.js-profile-role').text(codeToRole[this.options.userMe.role_type]);
        if (this.options.userMe.biography) {
            this.$card.find('.js-profile-bio').text(this.options.userMe.biography);
        }
        else {
            this.$card.find('.js-profile-bio').text('You dont have a biography yet.');
        }
        this.$card.find('.js-profile-resident').text(this.options.userMe.cities.map((city) => {
            return city.name;
        }).join(' | '));

        let $flags = [];

        this.options.userMe.flags.forEach((flag) => {
            let $clone = this.$sprite.clone();

            $clone.find('.sprite').addClass(`sprite-${flag.image}`);
            $flags.push($clone);
        });

        this.$card.find('.js-profile-languages').append($flags);
    },


    /**
     * Handle user logout
     * @param e {Event} click event object
     */
    handleLogout(e) {
        e && e.preventDefault();

        this.options.openLoader();

        Auth.logout((error) => {
            if (!error) {
                redirect('/');
            }
            else {
                this.options.closeLoader();
            }
        });
    },

    goToProfile(e) {
        e && e.preventDefault();
        let url = window.location.origin;
        redirect(`${url}/profile/${this.options.userMe.id}`);
    },

    /**
     * Bind Events
     */
    bindEvents() {
        this.$card.on('click', '.js-handle-logout', this.handleLogout.bind(this));
        this.$card.on('click', '.js-my-profile', this.goToProfile.bind(this));
    }
};


export default ProfileCard;
