import HeaderNav from 'components/header-nav';

'use strict';

const LandingView = {
    /**
     * Initialize the view
     */
    init() {

        $.material.init();
        $('body').removeClass('hidden');
        HeaderNav.init();
    }
};

/* start-test */
/* istanbul ignore if  */
if (!window.__karma__) {
    /* end-test */
    LandingView.init();
    /* start-test */
}

/* end-test */
export default LandingView;
