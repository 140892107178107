import 'remodal';
import 'bootstrap-validator';
import loaderHtml from 'components/loader';
import { getUrlSearchParam } from 'utils/helpers';
import Auth from 'utils/auth';


'use strict';

const RecoverPassword = {

    /**
     * Initialize the Password module
     */
    init(options) {
        this.$recover = $('#recover-pass');
        this.$modal = $('#recover-pass').remodal({ hashTracking: false });
        this.$recover.append(`<div class="loader-overlay">${loaderHtml}</div>`);
        this.opt = $.extend({}, options);
        $('#recover-pass').find('form').validator({
            disable: false,
            feedback: {
                success: '',
                error: ''
            }
        });
        this.checkReset();
        this.bindEvents();
    },


    /**
     * Check if token is present in the url and check its validity
     */
    checkReset() {
        if (getUrlSearchParam('resetpwd') === 'true') {
            let self = this;
            Auth.checkAuthState((isLoggedIn) => {
                if (!isLoggedIn) {
                    self.token = getUrlSearchParam('token');
                    window.Api.Auth.checkResetTokenStatus(self.token).then((res) => {
                        if (res.isOk) {
                            self.showRecoverPane('#recover-pass-set');
                        }
                        else {
                            self.showRecoverPane('#recover-pass-set-error');
                        }
                        self.open();
                    }, () => {
                        self.showRecoverPane('#recover-pass-set-error');
                        self.open();
                    });
                }
            });
        }
    },

    /**
     * Method to show/hide the form tab pane
     * @param {string} paneId - the id selector of the tab pane to show (required)
     * @param {Boolean} noAnimation - if true then no transition animation will be applied
     */
    showRecoverPane(paneId, noAnimation) {
        const $panes = $('div.recover-pane'),
            $paneToHide = $panes.filter('.is-active'),
            $paneToShow = $(paneId);

        if (`#${$paneToHide.prop('id')}` === paneId) {
            return;
        }

        $paneToHide.removeClass('is-active');
        $paneToShow.addClass('is-active');

        if (!noAnimation) {
            $panes.addClass('is-animating');
            $paneToHide.addClass('is-exiting');
        }

        $paneToShow.on('transitionend webkitTransitionEnd', this.handlePaneTransitionEnd.bind(this, $paneToShow, $paneToHide));
    },


    /**
     * Remove classes from the tab panes after the transition animation completes
     * @param {Object} $paneToShow - the jQuery selector for the pane that becomes visible
     * @param {Object} $paneToHide - the jQuery selector for the pane that becomes hidden
     */
    handlePaneTransitionEnd($paneToShow, $paneToHide) {
        $paneToHide.removeClass('is-animating is-exiting');
        $paneToShow.removeClass('is-animating is-exiting').off('transitionend webkitTransitionEnd');
    },


    /**
     * Open recover Password popup
     * @param {Mixed} click event object or boolean
     */
    open(e) {
        e && e.preventDefault();
        if (e) {
            const value = this.opt.feederField.val().trim();
            if (value && value.length) {
                $('#recover-email').val(value).closest('.form-group').removeClass('is-empty').addClass('is-focused');
            }
        }
        this.$modal.open();
    },


    /**
     * Close recover password popup
     * @param e {Event} click event object
     */
    close(e) {
        e && e.preventDefault();
        this.$modal.close();
    },


    /**
     * Handle close event of the modal
     */
    handleClose() {
        this.opt.reOpen && this.opt.reOpen.open();
        this.showRecoverPane('#recover-pass-send');
        window.setTimeout(() => {
            $('#recover-email').val('').closest('.form-group').addClass('is-empty').removeClass('is-focused has-success has-error has-danger');
            $('#set-pass1').val('').closest('.form-group').addClass('is-empty').removeClass('is-focused has-success has-error has-danger');
            $('#set-pass2').val('').closest('.form-group').addClass('is-empty').removeClass('is-focused has-success has-error has-danger');
        }, 500);
    },

    /**
     * Handle send link form submission
     * @param e {Event} submit event object
     */
    sendLink(e) {
        if (!e.isDefaultPrevented()) {
            e.preventDefault();
            this.$recover.addClass('is-loading');
            const email = $('#recover-email').val().trim();
            window.Api.Auth.sendResetPasswordEmail(email).then((res) => {
                this.$recover.removeClass('is-loading');
                if (res.isOk) {
                    this.showRecoverPane('#recover-pass-sent');
                }
                else {
                    $.snackbar({ content: 'You are not a member of Transwap, please register with us first.' });
                }
            }, () => {
                $.snackbar({ content: 'Could not send you an email' });
                this.$recover.removeClass('is-loading');
            });
        }
    },


    /**
     * Handle change of password request
     * @param e {Event} submit event object
     */
    changePass(e) {
        if (!e.isDefaultPrevented()) {
            e.preventDefault();

            this.$recover.addClass('is-loading');
            const pass = $('#set-pass1').val().trim();
            window.Api.Auth.resetPassword(this.token, pass).then((res) => {
                this.$recover.removeClass('is-loading');
                if (res.isOk) {
                    this.showRecoverPane('#recover-pass-set-success');
                }
                else {
                    this.showRecoverPane('#recover-pass-set-error');
                }
            }, () => {
                this.showRecoverPane('#recover-pass-set-error');
                this.$recover.removeClass('is-loading');
            });
        }
    },


    /**
     * Bind events
     */
    bindEvents() {
        this.$recover.on('click', '.js-close-recover', this.close.bind(this));
        $(document).on('click', '.js-open-recover-modal', this.open.bind(this));
        this.$recover.on('closed', this.handleClose.bind(this));
        $('#send-link-form').on('submit', this.sendLink.bind(this));
        $('#set-pass-form').on('submit', this.changePass.bind(this));
    }
};


export default RecoverPassword;
