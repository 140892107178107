import makeApiRequest from 'utils/api';

'use strict';

/**
 * Get the value of the requested param from the current page url
 * @param {String} s - the requested search query
 * @returns {String}
 */
export function getUrlSearchParam(s) {
    const allQueries = window.location.search.slice(1).split('&');
    let val = '';

    allQueries.forEach((query) => {
        /* istanbul ignore else */
        if (query.indexOf(s) !== -1) {
            val = query.split('=')[1];
        }
    });

    return val;
}

/**
 * Get the value of the requested param from the current page url (this is for Friendly urls)
 * @returns {String}
 */
export function newFriendlyUrlParam() {
    const allQueries = window.location.pathname.split('/');
    let val = '';

    val = allQueries.pop();

    return val;
}


/**
 * Add/update query param to the current page url
 * @param {String} key - the name of the query param
 * @param {String} val - the value of the query param
 */
export function setUrlSearchParam(key, val) {
    const url = window.location.href,
        replacement = (typeof val === 'undefined' || val === null)
            ? ''
            : `&${key}=${encodeURIComponent(val)}`;

    return url
        .replace(RegExp(`([?&]${key}(?=[=&#]|$)[^#&]*|(?=#|$))`), replacement)
        .replace(/^([^?&]+)&/, '$1?');
}


/**
 * Perform page redirection
 * @param {String} path - the path to which the redirection should happen
 * @param {Boolean} replace - if true then the current url will be replaced in browser history
 */
/* istanbul ignore next */
export function redirect(path, replace) {
    if (replace) {
        window.location.replace(path);
    }
    else {
        window.location.href = path;
    }
}


/**
 * Format a number into groups of threes separated by commas
 * @param {Number} num - the number to be formatted
 * @returns {String}
 */
export function formatNumberIntoThousands(num) {
    let parts = num.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}


/**
 * Get the user's profile data from the api
 * @returns {Object} - jQuery ajax promise object
 */
export function getUserProfileData() {
    return makeApiRequest('users/me', null, 'get', true);
}


/**
 * Get date in the format "January 2, 2017"
 * @param {String|Number} datetime - either a datetime string or date in ms
 * @returns {String} - the formatted date
 */
export function getFormattedDate(datetime) {

    // need to replace "-" with "/" so that Safari recognizes it
    const date = typeof datetime === 'string' ? new Date(datetime.replace(/-/g, '/')) : new Date(datetime),
        months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ];

    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

export function DateForFinances(datetime1, datetime2) {

    // need to replace "-" with "/" so that Safari recognizes it
    const date1 = typeof datetime1 === 'string' ? new Date(datetime1.replace(/-/g, '/')) : new Date(datetime1);
    const date2 = typeof datetime2 === 'string' ? new Date(datetime2.replace(/-/g, '/')) : new Date(datetime2),
        months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ];

    return `${months[date1.getMonth()]} ${date1.getDate()} - ${date2.getDate()}`;
}

/**
 * Get hour in the format Interger
 * @param {Number}
 * @returns {String} - the formatted hour (like: 08 PM).
 */
export function getRealHour(hour) {
    const hours = {
        13: '01',
        14: '02',
        15: '03',
        16: '04',
        17: '05',
        18: '06',
        19: '07',
        20: '08',
        21: '09',
        22: '10',
        23: '11'
    };
    return `${hours[hour]} PM`;
}

/**
 * Initiate fb comments
 * @param {jQuery} $container
 */
export function initFBComments($container) {
    $container.append('<div class="fb-comments" data-href="' + window.location.href + '" data-width="100%" data-numposts="3"></div>');
}


/**
 * Set page's metadata
 * @param data
 */
export function setMetaData(data) {
    let $head = $('head');
    $head.find('title').text(`${data.title} | Mundo Lingo`);
    $head.find('meta[name=description]').text(data.description || '');
    $head.find('meta[name=keywords]').text(data.keywords || '');
}

/**
 * Set page's metadata
 * @param data
 */
export function setMetaData2(data) {
    let $head = $('head');
    $head.find('title').text(`Mundo Lingo | ${data.title}'s international meeting point`);
    $head.find('meta[name=description]').text(data.description || '');
    $head.find('meta[name=keywords]').text(data.keywords || '');
}

export function scrollToBottom(fn) {
    $(window).scroll(() => {
        if (($(window).scrollTop() + $(window).height()) >= ($(document).height() - 50)) {
            fn();
        }
    });
}


/**
 * @param {int} number - the concept number
 * @returns {String} the concept itself as string
 */
export function getSupplierConcept(number) {

    const concept = {
        1: 'Receive Bitcoin',
        2: 'Receive Cash',
        3: 'Sell Bitcoin',
        4: 'Pay for items',
        5: 'Print Shop',
        6: 'Delivery Company',
        7: 'Supplies/Stationary/Others',
    };
    return `${concept[number]}`;

}
