import 'remodal';
import loaderHtml from 'components/loader';
import Confirmbox from 'components/confirm-box';
import moment from 'moment-timezone';
import { redirect } from 'utils/helpers';


'use strict';

const CitiesPopup = {
    /**
     * Initialize the MenuPopup features
     */
    init(options) {
        this.df = $.Deferred();
        this.options = options || {};

        this.options.isAdmin ? this.initTimezones() : this.removeAdminComponents();

        Confirmbox.init();

        this.$popup = $('#cities-popup');
        this.$modal = this.$popup.remodal({ hashTracking: false });
        this.$popup.append(`<div class="loader-overlay">${loaderHtml}</div>`);
        this.$continentCityClone = $('.continent-city-clone').remove().clone().removeClass('hidden continent-city-clone');

        this.bindEvents();
        this.loadData();

        return this.df.promise();
    },

    /**
     *
     */
    initTimezones() {
        const data = moment.tz.names();
        const options = {
            items: data,
            paging: false,
            pageSize: 0,
            showSpinner: true,
            debounce: 500,
            render: item => `<p>${item}</p>`
        };

        $('.js-city-timezone-americas').richAutocomplete(options);
        $('.js-city-timezone-asia').richAutocomplete(options);
        $('.js-city-timezone-oceania').richAutocomplete(options);
        $('.js-city-timezone-europe').richAutocomplete(options);
    },

    /**
     * Opens the city removal confirmation
     * @param {Object} Event
     */
    removeCityConfirm(e) {
        e.preventDefault();
        e.stopPropagation();

        let $cityPopup = this.$modal;
        let $elem = $(e.target).closest('.continent-city');
        let cityId = $(e.target).attr('data-city-id');
        let options = {
            action: 'remove',
            item: 'this City',
            onAccept: () => {
                $cityPopup.open();
                $elem.fadeOut(500);
                this.removeCity(cityId);
            },
            onDecline: () => {
                $cityPopup.open();
            }
        };

        Confirmbox.open(options);
    },


    // loadCurrencies() {
    //     $.ajax({
    //         url: 'https://blockchain.info/ticker',
    //         cache: false,
    //         type: 'get',
    //         success: (res) => {
    //             let options = '';
    //             for (let i in res) {
    //                 options += `<option value="${i}" ${i == 'USD' ? 'selected' : ''}>${i}</option>`;
    //             }
    //             $('.js-city-currency').append(options).trigger('change');
    //         }
    //     });
    // },

    loadCurrencies() {
        let that = this;
        let df = $.Deferred();

        var myHeaders = new Headers();
        myHeaders.append('apikey', 'h8xO69kpxZQsd4MdnAWnoeMO7QqV1RxT');

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch('https://api.apilayer.com/fixer/latest?&base=BTC', requestOptions)
            .then(response => response.text())
            .then(result => {
                let json = JSON.parse(result);
                that.currencies = json.rates;
                const rates = json.rates;
                // that.loadFinished && that.updatePrices();
                let options = '';
                for (let i in rates) {
                    options += `<option value="${i}" ${i == 'USD' ? 'selected' : ''}>${i}</option>`;
                }
                $('.js-waiting').addClass('hidden');
                $('.js-city-currency').append(options).trigger('change');
                df.resolve({ isOk: true });
            })
            .catch(error => console.log('error', error));

        return df;
    },

    /**
     * Load the city data from Api
     */
    loadData() {
        window.Api.City.getAll()
            .then(({ data }) => {
                this.renderData(data);
                this.df.resolve(data);
            })
            .catch(error => {
                $.snackbar({ content: 'An error occurred loading the cities.' });
                this.df.resolve([]);
            });
    },

    /**
     * Renders the appropriate cities to the continent columns
     * @param {Array} Continents
     */
    renderData(continents) {
        $('[data-continent="Americas"]').append(this.renderContinentCities(continents.Americas));
        $('[data-continent="Asia"]').append(this.renderContinentCities(continents.Asia));
        $('[data-continent="Europe"]').append(this.renderContinentCities(continents.Europe));
        $('[data-continent="Oceania"]').append(this.renderContinentCities(continents.Oceania));
    },

    /**
     * Process the cities data into city components
     * @param {Object} cities data
     * @returns {Array} city items
     */
    renderContinentCities(cities) {
        let items = [],
            sortedCities = [];

        sortedCities = this.sortingObject(cities);
        for (let city of sortedCities) {

            if (city.deleted_at == null) {
                let $cityItem = this.$continentCityClone.clone();
                $cityItem.find('.js-city-name').text(city.name);
                $cityItem.attr('data-city-name', city.name);
                $cityItem.attr('data-city-id', city.id);
                $cityItem.find('.js-remove-city').attr('data-city-id', city.id);

                if (this.options.cityId == city.id) {
                    $cityItem.addClass('active');
                    $cityItem.find('.js-remove-city').remove();
                }
                items.push($cityItem);
            }
        }

        return items;
    },

    /**
     * Sorts the object array
     * @param {Object} cities data
     * @returns {Object} cities data organized by name in alphabetical order.
     */
    sortingObject(cities) {
        let sorted = cities.sort(function sort(a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });

        return sorted;
    },

    /**
     * Removes a city using the Api
     * @param {Number} id
     */
    removeCity(id) {
        window.Api.City.deleteOne(id)
            .then(() => $(`[data-city-id='${id}']`).fadeOut(500))
            .catch(error => $.snackbar({ content: 'An error occurred while removing the city.' }));
    },

    /**
     * Removes the admin components
     */
    removeAdminComponents() {
        $('.js-remove-city').remove();
        $('.city-adder').remove();
    },

    /**
     * Opens the cities popup
     * @param {Object} Event
     */
    openPopup(e) {
        e && e.preventDefault();
        this.$modal.open();
    },

    /**
     * Closes the cities popup
     * @param {Object} Event
     */
    closePopup(e) {
        e && e.preventDefault();
        this.$modal.close();
    },

    /**
     * Closes any instance of city add component
     */
    closeCityAdd() {
        $('.city-adder').removeClass('city-adder--open');
        $('.js-city-timezone-input').val('');
        $('.js-city-name-input').val('');
    },

    /**
     * Opens the city add component in the given event context
     * @param {Object} Event
     */
    openCityAdd(e) {
        if (!this.currencies) {
            this.loadCurrencies();
        }
        let $target = $(e.target);
        let $element = $target.closest('.city-adder');
        $('.city-adder').removeClass('city-adder--open');

        $element.addClass('city-adder--open');

        window.removeEventListener('click', this.closeCityAdd);
        setTimeout(() => {
            window.addEventListener('click', this.closeCityAdd);
            $target.closest('.js-waiting').removeClass('hidden');
        }, 0);
    },

    /**
     * Add a temporary city component in the given column reference
     * @param {DOM Reference} column
     * @param {String} cityName
     */
    addTempCityToColumn(column, cityName) {
        let $cityItem = this.$continentCityClone.clone();

        $cityItem.find('.js-city-name').text(cityName);
        $cityItem.attr('data-temp-city', cityName);
        $cityItem.find('.js-remove-city').addClass('hidden');

        $(column).append($cityItem);
    },

    /**
     * Updates the temporary city
     * @param {String} Temporary city name
     * @param {Object} city data
     */
    updateTempCityInPlace(tempName, data) {
        let $city = $(`[data-temp-city='${tempName}']`);

        $city.removeAttr('data-temp-city');
        $city.find('.js-remove-city').removeClass('hidden');
        $city.find('.js-remove-city').attr('data-city-id', data.id);
        $city.attr('data-city-id', data.id);
    },

    /**
     * Updates the temporary city data and transforms it to a normal city item
     * @param {String} Temporary city name
     * @param {Object} city data
    */
    removeTempCity(tempName) {
        $(`[data-temp-city='${tempName}']`).fadeOut(500);
    },

    /**
     * Add city to database and to the view
     * @param {Object} Event
     */
    addCity(e) {
        e && e.preventDefault();

        let cityName = $(e.target).find('.js-city-name-input').val().trim();
        let cityTimezone = $(e.target).find('.js-city-timezone-input').val().trim();
        let actionContainer = $(e.target).parent('.continent-column__action')[0];
        let cityRegionColumn = $(actionContainer).siblings('.continent-column__list')[0];
        let cityRegion = $(cityRegionColumn).attr('data-continent');
        let cityCurrency = $(e.target).find('.js-city-currency').val();

        this.addTempCityToColumn(cityRegionColumn, cityName);
        this.closeCityAdd();

        window.Api.City.createOne({
            name: cityName,
            region: cityRegion,
            timezone: cityTimezone,
            currency: cityCurrency
        })
        .then(({ isOk, data }) => {
            isOk ?
                this.updateTempCityInPlace(cityName, data)
                : (this.removeTempCity(cityName), $.snackbar({ content: 'An error occurred while adding the city.' }));
        })
        .catch(error => {
            this.removeTempCity(cityName);
            $.snackbar({ content: 'An error occurred while adding the city.' });
        });
    },

    /**
     * Checks if the form can be submited
     * @param {Object} event
     */
    checkCanSubmit(e) {
        const timezone = $(e.target).parents('.js-add-city').find('.js-city-timezone-input')[0];
        const name = $(e.target).parents('.js-add-city').find('.js-city-name-input')[0];
        const currency = $(e.target).parents('.js-add-city').find('.js-city-currency')[0];
        const button = $(e.target).parents('.js-add-city').find('.js-submit-city')[0];

        $(timezone).val().trim().length && $(name).val().trim().length && $(currency).val().trim().length ? $(button).attr('disabled', false) : $(button).attr('disabled', true);
    },


    goToCity(e) {
        // MODIFICADA
        e.preventDefault();
        let name = $(e.target).closest('.continent-city').attr('data-city-name');

        let nameFinal = name.replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }).replace(/ /g, '-');

        redirect(`/city/${nameFinal}`);
    },

    /**
     * Bind events
     */
    bindEvents() {
        $('.cities-popup').on('click', '.js-remove-city', this.removeCityConfirm.bind(this));
        $('.continent-column__action').on('submit', '.js-add-city', this.addCity.bind(this));
        $('.continent-column__action').on('click', '.js-add-city', this.openCityAdd.bind(this));
        $('.continent-column__action').on('keyup change', '.js-add-city', this.checkCanSubmit.bind(this));
        $('.js-open-popup-cities').on('click', this.openPopup.bind(this));
        $('.js-close-popup-cities').on('click', this.closePopup.bind(this));
        $('.cities-popup').on('click', '.continent-city', this.goToCity.bind(this));
    }
};

export default CitiesPopup;
